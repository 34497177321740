import React from 'react';
import { Router } from '@reach/router';
import CompletedReservationContainer from '../containers/completed-reservation/CompletedReservation.container';

const CatalogDetailRouter = (props) => {
  return (
    <Router basepath="/reserva-completada" >
      <CompletedReservationContainer {...props} path="/"  />
    </Router>
  );
};

export default CatalogDetailRouter;
